@import url(https://fonts.googleapis.com/css?family=Cinzel&display=swap);
@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);
@import url(https://fonts.googleapis.com/css?family=Alata&display=swap);
@import url(https://fonts.googleapis.com/css?family=Baskervville&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
body{
  width: 100%;
}
header{
  height: 70px;
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: space-between;
}
.productoSolitario{
  width: 100%;
  max-width: 800px;
}
.bodyLogin{
  margin-left: 25px;
  margin-right:25px;
  padding-top: 0px;
  max-width: 500px;
}
.tituloLogin{
  margin-top: 25px;
  font-size: 36px;
  font-family: 'Baskervville';
  font-weight: 50;
  font-feature-settings: "c2sc";
  font-variant: small-caps;
}
.tituloMenu{
  margin-top: 35px;
  font-size: 27px;
  font-family: 'Baskervville';
  font-weight: 50;
  font-feature-settings: "c2sc";
  font-variant: small-caps;
  margin-bottom: 5px;
}
@media only screen and (min-width: 800px) {
  .tituloMenu{
    margin-top: 35px;
    font-size: 27px;
    font-family: serif;
    font-weight: 50;
    font-feature-settings: "c2sc";
    font-variant: small-caps;
    margin-bottom: 0px;
    text-align: center;
  }
  .contenedorProductoSolitario{
    width: 130%;
  }
  .cuadricula{
    display: flex;
    width: 50%;
    margin-top: 15px;
  }
  .cuadriculaResponsive{
    display: flex;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
  .bloqueFinal{
    display: flex;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
}

.textoNavegadorMenu{
  font-weight: 550;
  font-family: 'Alata';
  font-size: 18px;
  letter-spacing: 0.5px;
  text-align: center;
}
.cuadricula{
  display: flex;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}
.cuadrante{
  width: 50%;
}
.imagenCuadrante{
  width: 100%;
}
.cuadranteHorizontal{
  width: 100%;
  display: flex;
  margin-top: 0px;
}
.imagenCuadranteHorizontal{
  width: 33%;
}
.infoPrecioHorizontal{
  width: 33%;
  display: flex;
}
.bloquePrecio{
  width: 90%;
  margin-top: auto;
  margin-bottom: auto;
}
.nombre{
  text-align: center;
  margin-top: 10px;
  font-size: 32px;
  color: #4d4d4d;
  font-family: 'Alata';
}
.sinDecoracionHorizontales{
  display: flex;
  text-decoration: none;
  color: black;
}
.sinDecoracion{
  text-decoration: none;
  color: black;
}
.textoCuadranteHorizontal{
  width: 33%;
  display: flex;
  justify-content: center;
}
.enlaceLogin{
  text-decoration: none;
  color: white;
  margin-top: 10px;
  font-family: 'Alata';
  font-size: 18px;
  padding-bottom: 5px;
}
.productoCuadranteHorizontal{
  width: 80%;
  margin-top: auto;
  margin-bottom: auto;
  font-family: 'Alata';
  font-size: 18px;
}
.margen10{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.textoMenu{
  font-family: 'Alata';
  font-size: 16px;
  letter-spacing: 0.5px;
  text-align: center;
}
.bloqueRadio{
  display: flex;
  
}
.cajaRadio{
  width: 40px;
}
input[type=radio]{
  -webkit-appearance: none;
  border: 1.7px solid black;
  border-radius: 50%;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  height: 32px;
  width: 40px;
}
input[type=radio]:checked{
  -webkit-appearance: none;
  border: 11px solid black;
  border-radius: 50%;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  height: 35px;
  width: 36px;
}
.textoRadio{
  font-weight: 400;
  font-family: 'Alata';
  font-size: 20px;
  font-weight: 50;
  letter-spacing: 0.5px;
}
.cajaLogin{
  border: solid 1px;
  font-size:20px;
  font-family: 'Alata';
  letter-spacing: 0.5px;
  height: 40px;
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
  padding-left: 13px;
}
.centrado{
  text-align: center;
}
.cajaBoton{
  height: 40px;
  width: 100%;
  background-color: black;
  color: white;
  border: solid 1px black;
  font-size:20px;
  font-family: 'Alata';
  margin-top: 20px;
  margin-bottom: 30px;
  letter-spacing: 1px;
  font-weight: 400;
  padding-bottom: 4px;
}
.carouselAutomatico{
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}
.cajaBotonMenu{
  text-align: center;
  height: 50px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  background-color: black;
  color: white;
  border: solid 1px black;
  max-width: 700px;
  margin-bottom: 30px;
}
.textoCuadrante{
  display: flex;
  width: 100%;
}
.productoCuadrante{
  width: 50%;
  font-family: 'Alata';
  padding-left: 20px;
  padding-top: 10px;
  font-size: 18px;
  
}
.infoPrecio{
  width: 50%;
  
}
.precio{
  margin-left: auto;
  padding-left: 5px;
  display:flex;
  align-items: flex-end;
  justify-content: center;
  font-family: 'Cinzel';
    
}
.valor{
  font-size: 55px; 
  padding: 0px;
  margin: 0px;
}
.euro{
  font-size: 45px;
  margin-top: 0px;
  padding-bottom: 2px;
  -webkit-transform: scale(.8, 1);
          transform: scale(.8, 1);  
}
.textoParticipacion{
  font-family: 'Alata';
  padding-left: 5px;
  font-size:11px;
  text-align: center;
}
.colorBlanco{
  color: white;
  font-size:14px;
  border: 0px;
  background-color: black;
  font-family: 'Geneva';
  width: 100%;
  height: 100%;
  letter-spacing: 1px;
  font-weight: 600;
}
.mensajeError{
  text-align: center;
  margin-top: 15px;
  color: red;
  font-weight: bold;
}

.casilla{
  border: solid 1px;
  border-color: black;
  border-radius: 50px;
  width: 56px;
  height: 56px;
  text-align: center;
  font-family: "Cinzel";
  margin: 8px;
  font-size: 35px;
  font-weight: 549;
  background-color: white;
  padding-top: 5px;
}
.casillaPulsada{
  border: solid 0px;
  border-radius: 50px;
  width: 56px;
  height: 56px;
  text-align: center;
  font-family: "Cinzel";
  margin: 8px;
  font-size: 35px;
  background-color: black;
  color: white;
  padding-top: 5px;
}
.casillaGanadora{
  border-radius: 50px;
  width: 72px;
  height: 72px;
  text-align: center;
  font-family: "Cinzel";
  margin: 0px;
  color: white;
  border: 0px;
  font-size: 45px;
  font-weight: 900;
  background-color: green;
  padding-top: 6px;
}
.casillaPerdedora{
  border-radius: 50px;
  width: 72px;
  height: 72px;
  border: 0px;
  text-align: center;
  font-family: "Cinzel";
  margin: 0px;
  color: black;
  font-size: 45px;
  font-weight: 900;
  background-color: red;
  padding-top: 6px;
}
.casillaParpadeo{
  border: solid 1px;
  border-color: black;
  border-radius: 50px;
  width: 60px;
  height: 60px;
  text-align: center;
  font-family: "Cinzel";
  margin: 6px;
  font-size: 35px;
  font-weight: 700;
  padding-top: 5px;
  background-color: grey;
}
.botonJugar{
  display: block;
  font-size: 22px;
  width: 90%;
  padding-bottom: 5px;
  margin: auto;
  margin-top:40px;
  margin-bottom: 30px;
  text-align: center;
  height: 50px;
  font-weight: 500;
  font-family: 'Alata';
  border: solid 0px;
  color: white;
  background-color: black;
}

.mensajeGanador{
  border: 3px inset grey;
  border-radius: 10px;
  font-size: 35px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-weight: 700;
  margin-top: 10px;
  padding-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  width: 80%;
}
.textoVolver{
  font-size: 23px;
  margin-top: 20px;
  font-family: 'Alata';
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  width: 100%;
  background-color: white;
  border: 0px white;
  margin-bottom: 20px;
  font-weight: 600;
  margin-left: auto;
  margin-right: auto;

}
.mensajeAyuda{
  padding: 10px;
  border: solid 1px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 20px;
  width: 80%;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  font-family: 'Alata';
}
.modal-contenido{
  background-color:white;
  height: 150px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2px;
  padding-bottom: 5px;
  margin-top: 100px;
  text-align: center;
  border-radius: 10px;
}
.modal{
  background-color: rgba(0,0,0,.8);
  position:fixed;
  top:0;
  right:0;
  bottom:0;
  left:0;  
  opacity: 0;
  pointer-events: none;
  
}
.modalAyuda{
  background-color: rgba(0,0,0,.8);
  position:fixed;
  top:0;
  right:0;
  bottom:0;
  left:0;  
  opacity: 1;
}
.loader {
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid; /* Blue */
  
  border-radius: 50%;
  width: 70px;
  height: 70px;
  margin-left: auto;
  margin-right: auto;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;

}
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -webkit-transform-origin: 40px 40px;
          transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background:grey;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  -webkit-animation-delay: -0.036s;
          animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  -webkit-animation-delay: -0.072s;
          animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  -webkit-animation-delay: -0.108s;
          animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  -webkit-animation-delay: -0.144s;
          animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  -webkit-animation-delay: -0.18s;
          animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  -webkit-animation-delay: -0.216s;
          animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  -webkit-animation-delay: -0.252s;
          animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  -webkit-animation-delay: -0.288s;
          animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@-webkit-keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.mensajeAyuda{
  padding: 10px;
  border: solid 1px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 20px;
  width: 80%;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
}
.botonAyuda{
  border: 0px solid;
  width: 85%;
  margin: 20px;
  height: 50px;
  background-color: black;
  color: white;
  font-size: 20px;
  padding: 12px;
  max-width: 500px;
  font-family: 'Alata';
}
.usuario{
  text-align: center  ;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 32px;
  color: #4d4d4d;
  font-family: 'Alata';
}
.slider2 {
  width: 100%;
  height: 300px;
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
}
.slide2 {
  
  flex-shrink: 0;
  height: 100%;
  margin-right: 25px;
}
.tituloProducto{
  padding-left: 2%;
  padding-top: 5%;
  width: 90%;
  margin: auto;
  letter-spacing: 2px;
  font-family: "Alata";
  font-size: 35px;
  font-weight: 500;
}
.infoProducto{
  font-size: 13px;
  padding-left: 2%;
  width: 90%;
  margin: auto;
  font-family: "Alata";
  text-align: left;
  font-weight: 500;
}
.bloque1{
  display: flex;
  padding-left: 2%;
  padding-right: 2%;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.subbloque1{
  padding-left: 20px;
  margin-top:20px;
  width: 100%;
}
.bloqueCasillas{
  padding-top:30px ;
  display: flex;
  flex-wrap: wrap;
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}
.numero{
  font-size: 60px;
  font-family: "Cinzel";
}
.info2{
  font-size: 15px;
  width: 100%;
  font-family: "Verdana";
  font-weight: 500;
}
.rockselEnlace{
  height: 100%;
  border: 0px;
  text-decoration: none;
}
